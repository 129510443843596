import Menu from "../../components/uielements/menu";
import {Icon} from 'antd';
import React, { Component } from "react";
import { connect } from "react-redux";
import {Link} from "react-router-dom";
import appActions from "../../redux/app/actions";

const { SubMenu } = Menu;

const {
  changeCurrent,
} = appActions;

class TopbarMenu extends React.Component {
  handleClick = e => {
    this.props.changeCurrent([e.key]);
    // this.setState({
    //   current: e.key,
    // });
  };
  
  render() {
    // const {current} = this.state;
    const { app, user } = this.props;
    
    //if user ghave no advertiser assigned
    if(!user.permissions)
      return null;
    
    // console.log(user);
    return (
      <Menu onClick={this.handleClick} selectedKeys={app.current} mode="horizontal">
    
          <SubMenu
              key="campaigns"
              disabled={user && user.permissions['Campaigns'] !== undefined ? false : true}
              title={
            
                  <span key="leads" className={"submenu-title-wrapper" + (app.current.includes('campaigns') ? ' isSelected' : '')} >
                <Icon type="usergroup-add" />
                  Campaigns
              </span>
              }
          >
              <Menu.Item key="campaignReports"><Link to={'/reports/campaigns'}>Campaign Reports</Link></Menu.Item>
          </SubMenu>
          
        {/*<Menu.Item*/}
        {/*  key="campaigns"*/}
        {/*  disabled={user && user.permissions['Campaigns'] !== undefined ? false : true}*/}
        {/*>*/}
        {/*  <Link to={'/reports/campaigns'}><Icon type="dashboard" />Campaigns</Link>*/}
        {/*</Menu.Item>*/}

        <SubMenu
          key="leads"
          disabled={user && user.permissions['LeadsProspects'] !== undefined ? false : true}
          title={
            
              <span key="leads" className={"submenu-title-wrapper" + (app.current.includes('leads') ? ' isSelected' : '')} >
                <Icon type="usergroup-add" />
                  Leads
              </span>
          }
        >
          <Menu.Item key="prospects"><Link to={'/leads/prospects'}>Prospects</Link></Menu.Item>
            {user.isUser() ?
                <Menu.Item key="newProspect"><Link to={'/leads/new-prospect'}>Create prospect</Link></Menu.Item>
            : null}
          <Menu.Item key="daySchedule"><Link to={'/leads/schedule'}>Day Schedule</Link></Menu.Item>
            {user.isManager() ?
                <Menu.Item key="analysis"><Link to={'/leads/analysis'}>Analysis</Link></Menu.Item>
            : null}
            {user.isManager() ?
                <Menu.Item key="leadSources"><Link to={'/leads/lead-sources'}>Lead Sources</Link></Menu.Item>
            : null}
            {user.isManager() ?
                <Menu.Item key="importLeads"><Link to={'/leads/import'}>Import Leads</Link></Menu.Item>
                : null}
            <SubMenu
                disabled
                title={
                    <span className="submenu-title-wrapper">
              Email Offers
            </span>
                }
            >
                <Menu.Item key="setting:1">Offer List</Menu.Item>
                <Menu.Item key="setting:2"><Link to={'/leads/offers/templates'}>Offer Templates</Link></Menu.Item>
            </SubMenu>
        </SubMenu>
    
          <SubMenu
              disabled={!user.isManager()
              || (localStorage.getItem('advertiser_id') != 3
                  && localStorage.getItem('advertiser_id') != 34
                  && localStorage.getItem('advertiser_id') != 18
                  && localStorage.getItem('advertiser_id') != 19
                  && localStorage.getItem('advertiser_id') != 21
                  && localStorage.getItem('advertiser_id') != 12
                  && localStorage.getItem('advertiser_id') != 1
                  && localStorage.getItem('advertiser_id') != 36
                  && localStorage.getItem('advertiser_id') != 30
                  && localStorage.getItem('advertiser_id') != 37
                  && localStorage.getItem('advertiser_id') != 49)}
              key="delivery"
              // disabled={user && user.permissions['LeadsProspects'] !== undefined ? false : true}
              title={
            
                  <span key="delivery" className={"submenu-title-wrapper" + (app.current.includes('delivery') ? ' isSelected' : '')} >
                <Icon type="usergroup-add" />
                  Delivery
              </span>
              }
          >
              <Menu.Item key="messageCampaigns"><Link to={'/delivery/message-campaigns'}>Message Campaigns</Link></Menu.Item>
              <Menu.Item key="audiences"><Link to={'/delivery/audiences'}>Audiences</Link></Menu.Item>
              <Menu.Item key="emailTemplates"><Link to={'/delivery/templates'}>Templates</Link></Menu.Item>
          </SubMenu>
    
          <SubMenu
              key="googleBusiness"
              disabled={(localStorage.getItem('advertiser_id') != 1 && localStorage.getItem('advertiser_id') != 3 && localStorage.getItem('advertiser_id') != 33 && localStorage.getItem('advertiser_id') != 10 && localStorage.getItem('advertiser_id') != 47) || !user.isManager()}
              title={
            
                  <span key="googleBusiness" className={"submenu-title-wrapper" + (app.current.includes('google-business') ? ' isSelected' : '')} >
                <Icon type="google" />
                  Locations
              </span>
              }
          >
              <Menu.Item key="googleBusinessCampaigns"><Link to={'/google-business/location-campaigns'}>Location Campaigns</Link></Menu.Item>
              <Menu.Item key="googleBusinessLocations"><Link to={'/google-business/locations'}>Locations</Link></Menu.Item>
              <Menu.Item key="googleBusinessLocationGroups"><Link to={'/google-business/location-groups'}>Location Groups</Link></Menu.Item>
              <Menu.Item key="googleBusinessReviews"><Link to={'/google-business/reviews'}>Review control</Link></Menu.Item>
          </SubMenu>
        
        
        <SubMenu
          disabled
          title={
            <span className="submenu-title-wrapper">
              <Icon type="fire" />
              Heatmaps
            </span>
          }
        >
          <Menu.ItemGroup title="Item 1">
            <Menu.Item key="setting:1">Option 1</Menu.Item>
            <Menu.Item key="setting:2">Option 2</Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
  
        <SubMenu
          disabled
          title={
            <span className="submenu-title-wrapper">
              <Icon type="bar-chart" />
              Statistics
            </span>
          }
        >
          <Menu.ItemGroup title="Item 1">
            <Menu.Item key="setting:1">Option 1</Menu.Item>
            <Menu.Item key="setting:2">Option 2</Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
  
          {localStorage.getItem('advertiser_id') == 3 ?
              <Menu.Item key="help"><a href={'https://bitetrack.io/pomoc'} target={'_blank'}><Icon type="question-circle" />Help</a></Menu.Item>
              : null
          }
    
          {localStorage.getItem('advertiser_id') == 21 ?
              <Menu.Item key="help"><a href={'https://bitetrack.io/new/athlon-pomoc/'} target={'_blank'}><Icon type="question-circle" />Help</a></Menu.Item>
              : null
          }
        
      </Menu>
    );
  }
}

export default connect(
  state => ({
    app: state.App,
    user: state.Auth.user,
  }),
  { changeCurrent }
)(TopbarMenu);

import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from './actions';
import LeadsFetch from "../../helpers/leadsFetch";
import notifications from "../../components/feedback/notification";

export function* importLeadsRequest() {
  yield takeEvery(actions.CREATE_IMPORT_LEADS_REQUEST, function*(payload) {
    const importLeadsRequest = yield select((state) => state.LeadsImport.importLeadsRequest);
    const apiResponse = yield LeadsFetch.post('import-leads', importLeadsRequest)
        .then(response => (response))
        .catch(error => ({error}))

    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.RECEIVED_IMPORT_LEADS_PREVIEW,
        response: apiResponse.data.data
      });
      yield put({
        type: actions.RECEIVED_IMPORT_LEADS_REQUEST
      });
      return;
    }

    yield put({
      type: actions.CREATE_IMPORT_LEADS_REQUEST_ERROR,
      errorMessage: apiResponse.data.message
    });
  });
}

export function* confirmImportRequest() {
  yield takeEvery(actions.CONFIRM_IMPORT_LEADS, function*(payload) {
    const {confirmationId} = payload;
    const apiResponse = yield LeadsFetch.post('import-confirm', {import_confirmation_id: confirmationId})
        .then(response => (response))
        .catch(error => ({error}))
    
    if(apiResponse.status === 200)
    {
      yield put({
        type: actions.IMPORT_LEADS_COMPLETED
      });
      notifications['success']({
        message: 'Ok!',
        description: 'Leads sucessfully imported.'
      });
      return;
    }
    
    yield put({
      type: actions.IMPORT_LEADS_ERROR,
      errorMessage: apiResponse.data.message
    });
    notifications['error']({
      message: 'Server error!',
      description: apiResponse.data.message
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(importLeadsRequest),
    fork(confirmImportRequest),
  ]);
}

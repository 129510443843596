const actions = {

  CHANGE_SELECTED_LEADS: 'CHANGE_SELECTED_LEADS',

  SET_IMPORT_LEADS: 'SET_IMPORT_LEADS',

  SET_IMPORT_LEADS_EXTENDED_MAP: 'SET_IMPORT_LEADS_EXTENDED_MAP',
  DELETE_IMPORT_LEADS_EXTENDED_MAP: 'DELETE_IMPORT_LEADS_EXTENDED_MAP',

  SET_IMPORT_REQUEST_LEAD_SOURCE: 'SET_IMPORT_REQUEST_LEAD_SOURCE',
  SET_IMPORT_REQUEST_LEAD_CAUSE: 'SET_IMPORT_REQUEST_LEAD_CAUSE',
  SET_IMPORT_REQUEST_ALLOW_CREATE_PROSPECT_OPTION: 'SET_IMPORT_REQUEST_ALLOW_CREATE_PROSPECT_OPTION',
  SET_IMPORT_REQUEST_LEADS: 'SET_IMPORT_REQUEST_LEADS',
  ADD_IMPORT_REQUEST_ADDITIONAL_LOGIC: 'SET_IMPORT_REQUEST_ADDITIONAL_LOGIC',

  CREATE_IMPORT_LEADS_REQUEST: 'CREATE_IMPORT_LEADS_REQUEST',
  CREATE_IMPORT_LEADS_REQUEST_ERROR: 'CREATE_IMPORT_LEADS_REQUEST_ERROR',
  RECEIVED_IMPORT_LEADS_REQUEST: 'RECEIVED_IMPORT_LEADS_REQUEST',
  RECEIVED_IMPORT_LEADS_PREVIEW: 'RECEIVED_IMPORT_LEADS_PREVIEW',
  
  CONFIRM_IMPORT_LEADS: 'CONFIRM_IMPORT_LEADS',
  IMPORT_LEADS_COMPLETED: 'IMPORT_LEADS_COMPLETED',
  IMPORT_LEADS_ERROR: 'IMPORT_LEADS_ERROR',

  changeSelectedLeads: (selectedLeads) => ({
    type: actions.CHANGE_SELECTED_LEADS,
    selectedLeads
  }),

  setImportLeads: (importLeads) => ({
    type: actions.SET_IMPORT_LEADS,
    importLeads
  }),

  setImportLeadsExtendedMap: (columnName, mapValue) => ({
    type: actions.SET_IMPORT_LEADS_EXTENDED_MAP,
    columnName,
    mapValue
  }),

  deleteImportLeadsExtendedMap: (columnName) => ({
    type: actions.DELETE_IMPORT_LEADS_EXTENDED_MAP,
    columnName
  }),

  setImportRequestLeadSource: (leadSourceId) => ({
    type: actions.SET_IMPORT_REQUEST_LEAD_SOURCE,
    leadSourceId
  }),

  setImportRequestLeadCause: (leadCause) => ({
    type: actions.SET_IMPORT_REQUEST_LEAD_CAUSE,
    leadCause
  }),

  setImportRequestAllowCreateProspect: (allowCreate) => ({
    type: actions.SET_IMPORT_REQUEST_ALLOW_CREATE_PROSPECT_OPTION,
    allowCreate
  }),

  setImportRequestLeads: (parsedLeads) => ({
    type: actions.SET_IMPORT_REQUEST_LEADS,
    parsedLeads
  }),

  addImportRequestAdditionalLogic: (leadLogic) => ({
    type: actions.ADD_IMPORT_REQUEST_ADDITIONAL_LOGIC,
    leadLogic
  }),

  importLeadsRequest: () => ({
    type: actions.CREATE_IMPORT_LEADS_REQUEST,
  }),
  
  confirmLeadsImport: (confirmationId) => ({
    type: actions.CONFIRM_IMPORT_LEADS,
    confirmationId
  }),

};
export default actions;
